<script>
import * as utils from './shop-list-utils.js'

export default {
	lang: 'shop',
	metaInfo() {
		if (this.shop.invalid) return
		let title = this.shop.context.title
		let description = this.shop.context.description || title
		return {
			title,
			meta: [{ vmid: 'd', name: 'description', content: description }],
		}
	},
	hooksKey: 'ShopList',
	provide() {
		return {
			hookContainer: () => this,
			shop: () => this.shop,
		}
	},
	data() {
		return {
			shop: {
				fromCache: false,
				invalid: false,
				products: [],
				pagination: {
					page: 0,
					lastPage: 0,
					itemsLength: 0,
				},
				possibleFilters: {},
				appliedFilters: [],
				breadcrumbs: [],
				meta: {},
				context: {},
			},
			allProducts: [],
			filtersDesktopIntersect: true,
			moreProductsLoading: false,
			restartProductsListLoading: false,
			renderRestartProductsListBtn: false,
			showFiltersDrawer: false,
			showFiltersButton: false,
		}
	},
	watch: {
		$route() {
			this.$nextTick(() => {
				this.onShopLoaded()
			})
		},
	},
	computed: {
		categoryDescription() {
			return this.$srv('categoryDescription').find((c) => c.categoryId === this.shop.context.categoryId)
		},
		categoryImage() {
			return this.$b.d ? this.categoryDescription.img : this.categoryDescription.imgMobile
		},
		breadcrumbsItems() {
			return this.shop.breadcrumbs.map((breadcrumb) => ({
				text: breadcrumb.text,
				route: utils.getBreadcrumbRoute(this.$route, breadcrumb.value),
			}))
		},
		collectionImage() {
			return this.$vuetify.breakpoint.smAndDown
				? this.shop.context.imageMobileUrl
				: this.shop.context.imageUrl
		},
		renderMoreProductsBtn() {
			return this.shop.pagination.page < this.shop.pagination.lastPage
		},
		listId() {
			let { path, query } = this.$route
			if (query.search) return path + `?search=${query.search}`
			else return path
		},
	},
	methods: {
		onShopLoaded() {
			if (typeof this.$route.params?.filters === 'string') {
				this.$route.params.filters = this.$route.params.filters.split('/')
			}

			this.moreProductsLoading = false
			this.restartProductsListLoading = false
			this.showFiltersDrawer = false

			this.$eventer().trigger('shop-list:loaded')

			if (!this.shop.fromCache) {
				utils.setCache(this.$route, this.shop)
			}

			if (this.shop.invalid) return

			// trigger page view
			this.$meta().refresh()
			this.$trackers.pageView()

			// trigger products list view
			if (this.shop.products.length && !this.shop.fromCache) {
				const { page, itemsPerPage } = this.shop.pagination
				const initialIndex = itemsPerPage * (page - 1)
				this.shop.products.forEach((product, i) => (product.index = initialIndex + i))
				this.$eventer().trigger('product-list:view', {
					products: this.shop.products,
					listId: this.listId,
				})
			}
			if (!this.showFiltersButton && this.$b.mt) {
				this.$nextTick(() => {
					setTimeout(() => (this.showFiltersButton = true), 1000)
				})
			}
		},
		loadMoreProducts() {
			this.moreProductsLoading = true
			this.$router.replace({
				query: { ...this.$route.query, page: this.shop.pagination.page + 1 },
				params: { ...this.$route.params, scrollPosition: 'keep', allProducts: this.allProducts },
			})
		},
		restartProductsList() {
			this.restartProductsListLoading = true
			this.$router.push({
				query: { ...this.$route.query, page: undefined },
				params: { ...this.$route.params, scrollPosition: 'keep', allProducts: null },
			})
		},
		toggleDrawer() {
			this.showFiltersDrawer = !this.showFiltersDrawer
		},
		onIntersect(entries, observer) {
			if (window.scrollY == 0) {
				this.filtersDesktopIntersect = true
			} else {
				this.filtersDesktopIntersect = entries[0].isIntersecting
			}
		},
	},
	eventsListeners: {
		toggleFiltersDrawer() {
			this.toggleDrawer()
		},
	},
	/*mobileReload(context, to, from) {
		// do not reload when load more products
		return !to.params.allProducts
	},*/
	async resolveData(context, to, from, next) {
		const { ssr, apis, store, router } = context
		const { shopApi } = apis
		const page = Number(to.query.page) || 1

		store.set('shop/routeLoading', true)
		let shop = ssr ? null : utils.getCache(to)
		if (shop) {
			shop.fromCache = true
		} else {
			const postData = utils.routeToData(to)
			if (postData === false) {
				shop = { invalid: true }
			} else {
				postData.page = page
				await shopApi.post({
					url: '/catalog/shop',
					data: postData,
					loading: false,
					onSuccess: ({ data }) => {
						shop = data.shop
					},
					onError: ({ message }) => {
						// if(message?.code == 'invalid_filters')
						shop = { invalid: true }
					},
				})
			}
		}
		store.set('shop/routeLoading', false)

		const data = { shop }

		if (shop.invalid) return data

		if (shop.pagination.page == 1) {
			data.allProducts = shop.products
			data.renderRestartProductsListBtn = false
		} else if (to.params.allProducts) {
			data.allProducts = to.params.allProducts.concat(shop.products)
		} else {
			let prevProducts = ssr ? [] : utils.getAllCachedProducts(to, shop.pagination.page - 1)
			data.renderRestartProductsListBtn = !prevProducts.length
			if (!prevProducts.length) to.params.scrollPosition = 'reset'
			data.allProducts = prevProducts.concat(shop.products)
		}

		return data
	},
	mounted() {
		this.onShopLoaded()
	},
}
</script>

<template>
	<NotFoundPage v-if="shop.invalid" />
	<div v-else>
		<div class="pb-md-6" v-if="categoryDescription && categoryImage">
			<Media width="100%" :src="categoryImage" />
		</div>
		<div class="pb-6" v-else-if="collectionImage">
			<Media width="100%" :src="collectionImage" />
		</div>
		<v-container
			class="breadcrumbs-container"
			:class="!$b.d ? 'mt-2' : 'mt-3'"
			v-if="breadcrumbsItems.length"
		>
			<!-- <h1 class="header font-4 font-weight-bold text-uppercase">
					{{ breadcrumbsItems[breadcrumbsItems.length - 1].text }}
				</h1> -->
			<div class="d-flex justify-space-between align-center w100">
				<Breadcrumbs :items="breadcrumbsItems" />
				<ShopSortSelect
					v-if="shop.possibleFilters.sortBy.length && $b.d"
					:sortByValue="shop.sortByValue"
					:possibleFilters="shop.possibleFilters.sortBy"
					type="sortBy"
					label="Ordenar por"
				/>
			</div>
		</v-container>
		<v-container width="1280px" class="pt-6">
			<v-row>
				<v-col cols="12" v-if="$b.mt">
					<ShopFilters
						class="px-4 px-sm-0"
						h1-title
						:title="shop.context.title"
						:total-results="shop.pagination.itemsLength"
						:applied-filters="shop.appliedFilters"
						mobileFilter
					/>
				</v-col>
				<v-col cols="12" sm="4" md="2" v-if="$b.d">
					<hook zone="possible-filters">
						<ShopFilters
							class="pr-2"
							h1-title
							:title="shop.context.title"
							:total-results="shop.pagination.itemsLength"
							:applied-filters="shop.appliedFilters"
							:possible-filters="shop.possibleFilters"
							v-intersect="onIntersect"
						/>
					</hook>
					<div
						:class="!filtersDesktopIntersect ? 'filter-desktop-btn--show' : 'filter-desktop-btn'"
						:style="$srv('AppBarConfig.type') === 'round' && 'top: 100px!important'"
					>
						<Button @click="toggleDrawer" color="primary" block class="rounded">
							<v-icon color="var(--primarytext)">mdi-filter</v-icon>
							<span style="color: var(--primarytext)">
								{{ 'FILTRAR' | lang }}
							</span>
						</Button>
					</div>
				</v-col>

				<v-col cols="12" md="10">
					<DescriptionExpandable
						class="mb-6"
						v-if="categoryDescription"
						:categoryDescription="categoryDescription"
					/>
					<template v-if="allProducts.length">
						<div class="d-flex justify-center py-6" v-if="renderRestartProductsListBtn">
							<Button
								color="link"
								rounded
								lg
								@click="restartProductsList"
								:loading="restartProductsListLoading"
							>
								Ver productos desde el comienzo
							</Button>
						</div>
						<ProductsLayout
							:products="allProducts"
							:list-id="listId"
							:trigger-list-view="false"
							:cardsPerLine="$b.m ? 2 : $b.t ? 3 : 4"
						/>
						<div class="d-flex justify-center py-6" v-if="renderMoreProductsBtn">
							<Button color="link" rounded lg @click="loadMoreProducts" :loading="moreProductsLoading">
								Cargar más productos
							</Button>
						</div>
					</template>
					<div v-else class="d-flex justify-center align-center pt-8 pb-16 flex-column">
						<v-icon x-large color="#ccc darken-1"> mdi-thumb-down</v-icon>
						<div class="font-2 font-weight-bold">Ups! Lo sentimos...</div>
						<div class="mb-8 font-2 px-4">No encontramos resultados para tu búsqueda.</div>
						<template v-if="shop.noResultsProducts && shop.noResultsProducts.length">
							<div class="text-left w100">
								<h2 class="mb-4">{{ shop.noResultsText }}</h2>
							</div>
							<ProductsLayout
								:products="shop.noResultsProducts"
								list-id="shop-no-results"
								:cards-per-line="4"
							/>
						</template>
						<Button color="link" :to="$shop.getShopRoute()">Volver al inicio</Button>
					</div>
				</v-col>
				<v-navigation-drawer
					:value="showFiltersDrawer"
					stateless
					fixed
					:width="$b.m ? '100%' : '350px'"
					style="z-index: 11"
					class="base"
				>
					<v-list-item class="px-4">
						<div>{{ 'FILTRAR / ORDENAR' | lang }}</div>
						<v-spacer />
						<Button icon color="link" @click="toggleDrawer">
							<v-icon large>mdi-close</v-icon>
						</Button>
					</v-list-item>
					<v-divider />
					<div class="pa-4">
						<hook zone="possible-filters">
							<ShopFilters
								:sortByValue="shop.sortByValue"
								:title="shop.context.title"
								:total-results="shop.pagination.itemsLength"
								:applied-filters="shop.appliedFilters"
								:possible-filters="shop.possibleFilters"
							/>
						</hook>
					</div>
				</v-navigation-drawer>
			</v-row>
		</v-container>
		<transition v-if="$b.mt">
			<Button
				v-if="!$store.get('shop/routeLoading') && showFiltersButton && ($b.t || !$srv('ActionBarMobile'))"
				class="bottom-filter-btn"
				@click="toggleDrawer"
				block
				color="primary"
				large
			>
				<v-icon>mdi-filter</v-icon>
				{{ 'FILTRAR / ORDENAR' | lang }}
			</Button>
		</transition>
	</div>
</template>

<style lang="scss" scoped>
.breadcrumbs-container {
	border-bottom: 1px solid #ccc;
}
.container {
	max-width: 1280px;
}

.filter-desktop-btn--show {
	position: sticky;
	top: 140px;
	opacity: 1;
	transition: 0.3s top;
}

.filter-desktop-btn {
	position: sticky;
	top: 0;
	opacity: 0;
}

.bottom-filter-btn {
	position: fixed;
	bottom: 0px;
	left: 0px;
	z-index: 2;
	height: 60px !important;
	&.v-enter-active,
	&.v-leave-active {
		transition: transform 0.5s;
	}
	&.v-enter,
	&.v-leave-to {
		transform: translateY(100%);
	}
}
</style>

