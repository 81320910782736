<script>
export default {
	lang: 'shop',
	props: {
		sortByValue: String,
		h1Title: Boolean,
		title: String,
		totalResults: Number,
		appliedFilters: Array,
		possibleFilters: Object,
		mobileFilter: { type: Boolean, default: false },
	},
	data() {
		return {
			shopSidebarBanners: this.$srv('shopSidebarBanner', []),
		}
	},
	computed: {
		sidebarBannersAbove() {
			return this.shopSidebarBanners.filter((i) => i.position == 'above') || []
		},
		sidebarBannersBelow() {
			return this.shopSidebarBanners.filter((i) => i.position == 'below') || []
		},
	},
}
</script>

<template>
	<div>
		<component :is="h1Title ? 'h1' : 'div'" class="font-2 font-weight-bold">
			<!-- {{ 'Filtros aplicados' | lang }} -->
			{{ title }}
		</component>
		<div class="mt-1 font-1">{{ totalResults }} {{ 'resultado/s' | lang }}</div>
		<ShopFiltersRemovalChips v-bind="{ appliedFilters }" />

		<!-- Banners arriba de filtros -->
		<div
			v-for="(item, i) of sidebarBannersAbove"
			:key="`above-${i}`"
			class="mt-4"
			:class="{ 'd-none': mobileFilter }"
		>
			<SafeLink :to="item.link">
				<Media :src="item.img" />
			</SafeLink>
		</div>

		<template v-if="totalResults > 0 && possibleFilters">
			<ShopSortSelect
				v-if="possibleFilters.sortBy.length && $b.mt"
				class="mt-4"
				:sortByValue="sortByValue"
				:possibleFilters="possibleFilters.sortBy"
				type="sortBy"
				label="Ordenar por"
			/>
			<div>
				<ShopFiltersGroup
					v-for="(collection, key) in possibleFilters.collections"
					:key="`collection-${key}`"
					:items="collection"
					type="collection"
					no-auto-order
					:title="collection[0] && collection[0].groupName"
				/>
			</div>
			<!-- <ShopFiltersGroup :items="possibleFilters.sortBy" :title="$lang('Ordenar por')" type="sortBy" /> -->
			<ShopFiltersGroup :items="possibleFilters.category" :title="$lang('Categoría')" type="category" />
			<ShopFiltersGroup :limit="5" :items="possibleFilters.brand" :title="$lang('Marca')" type="brand" />
			<ShopFiltersGroup
				:limit="5"
				v-for="(group, i) of possibleFilters.attrsGroups"
				:key="i"
				:items="group.items"
				:title="group.title"
				type="attr"
			/>
		</template>

		<!-- Banners abajo de filtros -->
		<div
			v-for="(item, i) of sidebarBannersBelow"
			:key="`below-${i}`"
			class="mt-4"
			:class="{ 'd-none': mobileFilter }"
		>
			<SafeLink :to="item.link">
				<Media :src="item.img" />
			</SafeLink>
		</div>
	</div>
</template>

